import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        user: {
            phone: null,
            countryPhoneCode: null,
            email: null,
            fname: null,
            lname: null,
            currency: null,
            password: null,
            token: false,
            partner: null,
            suid : null,
            utm_campaign : null,
            utm_source : null,
            utm_medium : null,
            utm_term : null,
            utm_content : null,
            utm_keyword : null,
            sidi : null,
            sidc : null,
            campaign_id : null,
            channel_id : null,
            utm_device : null,
            utm_creative : null,
            utm_network : null,
        },
    },
    mutations: {
        setUserPhone (state, payload) {
            state.user.phone = payload
        },
        setPartner (state, payload) {
            state.user.partner = payload
        },
        setSuid (state, payload) {
            state.user.suid = payload
        },
        setUtmCampaign (state, payload) {
            state.user.utm_campaign = payload
        },
        setUtmSource (state, payload) {
            state.user.utm_source = payload
        },
        setUtmMedium (state, payload) {
            state.user.utm_medium = payload
        },
        setUtmTerm (state, payload) {
            state.user.utm_term = payload
        },
        setUtmContent (state, payload) {
            state.user.utm_content = payload
        },
        setUtmKeyword (state, payload) {
            state.user.utm_keyword = payload
        },
        setSidi (state, payload) {
            state.user.sidi = payload
        },
        setSidc (state, payload) {
            state.user.sidc = payload
        },
        setCampaignId (state, payload) {
            state.user.campaign_id = payload
        },
        setChannelId (state, payload) {
            state.user.channel_id = payload
        },
        setUtmDevice (state, payload) {
            state.user.utm_device = payload
        },
        setUtmCreative (state, payload) {
            state.user.utm_creative = payload
        },
        setUtmNetwork (state, payload) {
            state.user.utm_network = payload
        },
        setUserPhoneCode (state, payload) {
            state.user.countryPhoneCode = payload
        },
        setUserToken (state, payload) {
            state.user.token = payload
        },
        setUserFirstName (state, payload) {
            state.user.fname = payload
        },
        setUserLastName (state, payload) {
            state.user.lname = payload
        },
        setUserEmail (state, payload) {
            state.user.email = payload
        },
        setUserCurrency (state, payload) {
            state.user.currency = payload
        },
        setUserPassword (state, payload) {
            state.user.password = payload
        }
    },
    getters: {
        getUserPhone: state => {
            return state.user.phone;
        },
        getPartner: state => {
            return state.user.partner;
        },
        getSuid: state => {
            return state.user.suid;
        },
        getUtmCampaign: state => {
            return state.user.utm_campaign;
        },
        getUtmSource: state => {
            return state.user.utm_source;
        },
        getUtmMedium: state => {
            return state.user.utm_medium;
        },
        getUtmTerm: state => {
            return state.user.utm_term;
        },
        getUtmContent: state => {
            return state.user.utm_content;
        },
        getUtmKeyword: state => {
            return state.user.utm_keyword;
        },
        getSidi: state => {
            return state.user.sidi;
        },
        getSidc: state => {
            return state.user.sidc;
        },
        getCampaignId: state => {
            return state.user.campaign_id;
        },
        getChannelId: state => {
            return state.user.channel_id;
        },
        getUtmDevice: state => {
            return state.user.utm_device;
        },
        getUtmCreative: state => {
            return state.user.utm_creative;
        },
        getUtmNetwork: state => {
            return state.user.utm_network;
        },
        getUserPhoneCode: state => {
            return state.user.countryPhoneCode;
        },
        getUserToken: state => {
            return state.user.token;
        }

    }
});