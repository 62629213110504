import Vue from 'vue'
import VueRouter from 'vue-router'
import {store} from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'PhoneVerification',
        // component: PhoneVerification,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "phoneVerify" */ '../views/PhoneVerification.vue'),
        // children: [
        //     {
        //         path: '/login',
        //         name: 'Login',
        //         component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue') ,
        //     },
        //     {
        //         path: '/register',
        //         name: 'Register',
        //         // route level code-splitting
        //         // this generates a separate chunk (about.[hash].js) for this route
        //         // which is lazy-loaded when the route is visited.
        //         component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
        //         beforeEnter(to, from, next) {
        //             if (store.getters.getUserToken) {
        //                 next()
        //             } else {
        //                 next({
        //                     name: "PhoneVerification" // back to safety route //
        //                 });
        //             }
        //         }
        //     },
        // ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue') ,
    },
    {
        path: '/register',
        name: 'Register',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
        beforeEnter(to, from, next) {
            if (store.getters.getUserToken) {
                next()
            } else {
                next({
                    name: "PhoneVerification" // back to safety route //
                });
            }
        }
    },

    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    base: "/" ,
    routes
})


export default router
